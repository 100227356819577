import NavGrid, { GridItem, NavGridItemStill } from '../../generic/NavGrid';
import React, { useEffect, useState } from 'react';
import Section from '../../generic/Section';
import NavGridItemHero from '../../generic/NavGrid/NavGridItemHero';
import { universalMargin } from 'styles';
import { WorkspaceWithLinkRequirements } from 'types';
import getRoute from 'routes/getRoute';
import { isBuildTargetWebex } from 'white-label';
import { NavGridLink } from 'components/PwpNavGrid';
import styled, { css } from 'styled-components';

type WorkspaceDevicesProps = {
  heading: string;
  workspaces: Array<WorkspaceWithLinkRequirements>;
  description?: string;
  id?: string;
};

export default function WorkspaceDevices({
  heading,
  workspaces,
  description = '',
  id = undefined,
}: WorkspaceDevicesProps) {
  const [qualifiedWorkspaces, setQualifiedWorkspaces] = useState<Array<WorkspaceWithLinkRequirements>>([]);

  useEffect(() => {
    setQualifiedWorkspaces(workspaces?.filter(wsp => getRoute(wsp))?.filter(Boolean)); //? needed filtering here?
  }, [workspaces]);

  if (!qualifiedWorkspaces || qualifiedWorkspaces.length === 0) {
    return null;
  }

  return (
    <StyledSection id={id} size="max" webexStyle={isBuildTargetWebex()}>
      <NavGrid mapRowHeightToColumnWidth>
        <NavGridItemHero heading={heading} description={description} />
        {qualifiedWorkspaces?.map((wsp, index) => {
          return <GridItemWrapper key={index} wsp={wsp} />;
        })}
      </NavGrid>
    </StyledSection>
  );
}

const StyledSection = styled(Section)<{ webexStyle: boolean }>`
  margin-top: ${universalMargin * 6}px;
  ${props =>
    props.webexStyle
      ? css`
          margin-bottom: ${universalMargin * 12}px;
        `
      : css`
          margin-bottom: ${universalMargin * 6}px;
        `}
`;

type GridItemWrapperProps = {
  wsp: WorkspaceWithLinkRequirements;
};

function GridItemWrapper({ wsp }: GridItemWrapperProps) {
  const [workspaceImage = wsp.hotspotHero] = wsp.images || [];
  const { x, y } = {
    x: !isNaN(workspaceImage?.focalPoint?.x) ? workspaceImage.focalPoint.x : 50,
    y: !isNaN(workspaceImage?.focalPoint?.y) ? workspaceImage?.focalPoint?.y : 50,
  };
  const imagePosition = `${x}% ${y}%`;

  return (
    <GridItem key={wsp.id} gridSize="DefaultWithImage">
      <NavGridLink to={getRoute(wsp)}>
        <NavGridItemStill
          key={wsp.id}
          objectFit="cover"
          objectPosition={imagePosition}
          heading={wsp.heading}
          description={wsp.workspaceSubCategory?.[0].description?.description}
          image={workspaceImage?.image}
          numberOfSeats={wsp.workspaceSubCategory?.[0].numberOfSeats} // always hidden with gridSize="DefaultWithImage"
          physicalSizeM2={wsp.workspaceSubCategory?.[0].physicalSizeM2} // always hidden with gridSize="DefaultWithImage"
          gridSize="DefaultWithImage"
          gridImagePad
        />
      </NavGridLink>
    </GridItem>
  );
}
