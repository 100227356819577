/**
 *  uniqueArrayByKey
 *
 * Example:
 * let arr, uniqueArray;
 * arr = [
 *    {id:"a", name:"Martin"},
 *    {id:"a", name:"Martin"},
 *    {id:"b", name:"Kjersti"},
 *    {id:"c", name:"Knut"},
 * ]
 * uniqueArray = uniqueArrayByKey(arr, JSON.stringify)
 * or
 * uniqueArray = uniqueArrayByKey(arr, el => el.id)
 *
 * @param  {Array} arr
 * @param  {Function} key
 */
export function uniqueArrayByKey<Type>(arr: Array<Type>, key: (item: Type) => string) {
  const seen = new Set();
  return arr?.filter(item => {
    const k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
}
