import React from 'react';
import styled from 'styled-components';
import { ImageBase } from 'types';

type SVGIconProps = {
  icon: ImageBase;
  className?: string; // TODO: remove dependency on this
  style?: object;
  preventFillRemoval?: boolean;
};

export default function SVGIcon(props: SVGIconProps) {
  if (props.icon.file.contentType === 'image/svg+xml' && props.icon.svg && props.icon.svg.content) {
    const svgContent = props.preventFillRemoval
      ? props.icon.svg.content
      : props.icon.svg.content.replace(/fill="(.)+?"/g, '');

    return <Wrapper className={props.className} dangerouslySetInnerHTML={{ __html: svgContent }} />;
  }

  return (
    <img
      className={props.className}
      alt={props.icon?.description}
      src={props.icon.file?.url}
      width={props.icon.file?.details?.image?.width}
      height={props.icon.file?.details?.image?.height}
    />
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
`;
