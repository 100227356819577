import Breadcrumbs, {
  BreadcrumbGoToAnchor,
  BreadcrumbLink,
  LocationBreadcrumbs,
} from 'components/SecondaryNavigation/Breadcrumbs';
import React, { useMemo } from 'react';
import ArticleHero from 'components/Article/ArticleHero';
import Head from 'components/Head';
import PricingDisclaimer from 'components/ProductList/PricingDisclaimer';
import ProductFeature from 'components/Products/ProductFeature';
import ProductListContent from 'components/ProductList/ProductListContent';
import Section from 'components/generic/Section';
import WorkspaceDevices from 'components/WorkspaceGrid/WorkspaceDevices';
import { graphql, PageProps } from 'gatsby';
import styled, { css } from 'styled-components';
import { gridPaddings } from 'styles';
import { Row, Column } from 'components/generic/Grid';
import { uniqueArrayByKey } from '../../utils/uniqueArrayByKey';
import { Product } from 'context/Quote/typedefs';
import { WorkspaceWithLinkRequirements } from 'types';
import InitialMargin from 'components/generic/InitialMargin';
import { isBuildTargetPwp, isBuildTargetWebex, isBuildTargetWwt } from 'white-label';

const sectionNavTitles = [
  { id: 'overview', title: 'Overview' },
  { id: 'products', title: 'Specifications' },
  { id: 'featuredin', title: 'Featured in' },
];

export type ProductTemplateData = {
  contentfulProduct: Product;
};

export default function ProductTemplate({ data, location, path }: PageProps<ProductTemplateData, null>) {
  const product = data.contentfulProduct;
  const workspaces = useMemo(() => {
    if (!product.workspace) return [];
    return uniqueArrayByKey<WorkspaceWithLinkRequirements>(product.workspace, el => el.id);
  }, [product.workspace]);

  const heroSection = product.sections?.find(section => section.display === 'Hero');
  const featureSections = product.sections?.filter(section => section !== heroSection);

  return (
    <>
      <Head
        location={location}
        title={product.heading}
        description={product.description?.description}
        image={product.images?.[0].image.file.url}
      />
      <Toc
        productLink={location.pathname}
        productHeading={product.shortHeading || product.heading}
        sections={sectionNavTitles
          .filter(item => {
            // only display 'workspaces' if we got that section
            switch (item.id) {
              case 'featuredin': {
                return Boolean(workspaces && workspaces.length);
              }
              default:
                return true;
            }
          })
          .map(anchor => {
            if (anchor.id === 'specifications') return { ...anchor, title: product.shortHeading || product.heading };
            return anchor;
          })}
      />

      <InitialMargin />

      {heroSection ? (
        <StyledArticleHero
          webex={isBuildTargetWebex()}
          id={heroSection.id}
          heading={heroSection.heading || heroSection.shortHeading}
          image={heroSection.images?.[0].image}
          body={heroSection.description}
          eagerImageLoad
        />
      ) : (
        <StyledArticleHero
          webex={isBuildTargetWebex()}
          id={sectionNavTitles[0].id}
          heading={product.heading || product.shortHeading}
          body={product.description?.description}
          image={product.images?.[0].image}
          eagerImageLoad
        />
      )}
      {featureSections?.map((feature, index) => (
        <ProductFeature key={feature.id} feature={feature} id={index === 0 ? sectionNavTitles[0].id : undefined} />
      ))}
      <StyledProductListSection id={sectionNavTitles[1].id} size="max">
        <ProductListContent item={product} showHeading={false} />
        <Row>
          <Column desktop="5/12">
            <PricingDisclaimer />
          </Column>
        </Row>
      </StyledProductListSection>
      {(isBuildTargetPwp() || isBuildTargetWebex() || isBuildTargetWwt()) &&
      Array.isArray(workspaces) &&
      workspaces.length ? (
        <WorkspaceDevices
          id={sectionNavTitles[2].id}
          heading={`Workspaces featuring ${product.heading}`}
          workspaces={workspaces}
        />
      ) : null}
    </>
  );
}

const StyledProductListSection = styled(Section)`
  .inner {
    ${gridPaddings}
  }
`;

const StyledArticleHero = styled(ArticleHero)<{ webex: boolean }>`
  ${props =>
    props.webex
      ? css`
          padding-top: $universalMargin * 20;
        `
      : css`
          padding-top: $universalMargin * 14;
        `}
`;

type TocProps = {
  sections: Array<{ id: string; title: string }>;
  productLink: string;
  productHeading: string;
};

function Toc({ sections, productLink, productHeading }: TocProps) {
  return (
    <Breadcrumbs>
      <LocationBreadcrumbs>
        <BreadcrumbLink to="/products">Devices</BreadcrumbLink>
        <BreadcrumbLink to={productLink}>{productHeading}</BreadcrumbLink>
      </LocationBreadcrumbs>
      {sections.map(({ id, title }) => (
        <BreadcrumbGoToAnchor key={id} id={id}>
          {title}
        </BreadcrumbGoToAnchor>
      ))}
    </Breadcrumbs>
  );
}

// Important!!! 
// If you change this query, you must copy it to protectedProduct.tsx
export const query = graphql`
  query ($id: String!) {
    contentfulProduct(id: { eq: $id }) {
      ...Product
      sections {
        ...ProductFeature
      }
      workspace {
        ...WorkspaceGridCard
      }
    }
  }
`;
